import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { convertToLargerPossibleUnit } from '@/vue-app/utils/files';
import { v4 as uuid } from 'uuid';

// Infrastructure
import Functions from '@/modules/shared/infrastructure/utils/functions';

// Application
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import {
  SearchCustomerDocumentsQuery,
} from '@/modules/my-investment/customer-documents/application/queries';
import { GetFileQuery } from '@/modules/my-investment/file/application/queries';

// Domain
import {
  SearchAddressQuery,
} from '@/modules/my-investment/catalogs/kuspit/address/application/queries';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  CustomerDocumentEntity,
} from '@/modules/my-investment/customer-documents/domain/entities/customer-document-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ContractSavingsPersonalInformationAddressViewModel {
  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_ADDRESS_QUERY)
  private readonly searchAddressQuery!: SearchAddressQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_CUSTOMER_DOCUMENTS_QUERY)
  private readonly search_customer_documents_query!: SearchCustomerDocumentsQuery;

  @Inject(TYPES.MY_INVESTMENT_GET_FILE_QUERY)
  private readonly get_file_query!: GetFileQuery;

  @Inject(TYPES.UTIL_FUNCTIONS)
  readonly functions!: Functions;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  i18n_namespace = 'components.contract-savings.personal-information.address';

  internal_step = 1;

  small_screen: null | boolean = null;

  full_address_on_ine = '';

  address_proof: File | null = null;

  confirm_address = false;

  address_form = false;

  readonly limit_file_size = 4 * 1024 * 1024;

  readonly allowed_extensions = ['image/jpeg', 'image/jpg', 'image/png'];

  address_proof_accept = 'image/png, image/jpeg, image/jpg';

  investment_provider_name = '';

  exists_step = false;

  step_name = '';

  investment_provider_id = '';

  is_loading = true;

  invalid_file = false;

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {},
  };

  payload = {
    complete_and_correct_address: true,
    file: {
      id: uuid(),
      name: '',
      mime_type: '',
      file_data: '',
      file_weight: '',
    },
  };

  customer_documents: CustomerDocumentEntity[] = [];

  loadInvestmentsProviders = async () => {
    try {
      const investment_providers = await this.searchInvestmentProvidersQuery.execute();
      this.setInvestmentProviderId(investment_providers);
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_investment_providers`));
    }
  }

  loadSteps = async () => {
    try {
      const steps = await this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
      await this.verifyStep(steps);
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_steps`));
    }
  }

  loadCustomerDocuments = async () => {
    try {
      this.customer_documents = await this.search_customer_documents_query.execute();
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_customer_documents`));
    }
  }

  handleAddressProof = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const [file] = target.files;
      if (file.size <= this.limit_file_size && this.allowed_extensions.indexOf(file.type) > -1) {
        this.address_proof = file;
        this.invalid_file = false;
      } else {
        this.invalid_file = true;
      }
    }
  }

  get styledFileName(): string {
    if (this.address_proof) {
      return `${this.address_proof.name.slice(0, 30)}${this.address_proof.name.length > 35 ? '...' : ''}`;
    }

    return i18n.t(`${this.i18n_namespace}.address_proof.placeholder`).toString();
  }

  get styledFileSize(): string {
    if (this.address_proof) {
      return convertToLargerPossibleUnit(this.address_proof.size);
    }

    return '0 kb';
  }

  get canContinue(): boolean {
    if (this.small_screen) {
      return (this.full_address_on_ine !== '' && !this.invalid_file && !!this.address_proof)
        && this.confirm_address
        && this.address_form;
    }
    switch (this.internal_step) {
      case 1:
        return this.full_address_on_ine !== '' && !this.invalid_file && !!this.address_proof;
      case 2:
        return this.confirm_address && this.address_form;
      default:
        return true;
    }
  }

  convertFileToBase64 = async (selected_file: File) => (
    this.functions.convert_file_to_base_64(selected_file)
  );

  convertBase64ToFile = async (base64: string, mime_type: string, file_name: string) => (
    this.functions.convert_base_64_to_file(base64, mime_type, file_name)
  );

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      await this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = async () => {
    this.full_address_on_ine = this.on_boarding_step.payload.complete_and_correct_address
      ? 'yes' : 'no';
    await this.proofOfAddressFromStep();
  }

  proofOfAddressFromStep = async () => {
    await this.loadCustomerDocuments();
    const proof_of_address = this.customer_documents.find(
      (item) => item.document_type.name === 'proof_of_address',
    );
    if (proof_of_address) {
      const proof_of_address_file = await this.get_file_query.execute(
        proof_of_address.customer_document_id,
      );
      this.address_proof = proof_of_address_file && await this.convertBase64ToFile(
        proof_of_address_file.content, proof_of_address_file.mime_type, proof_of_address_file.id,
      );
    } else {
      this.address_proof = await this.convertBase64ToFile(
        this.on_boarding_step.payload.file.file_data,
        this.on_boarding_step.payload.file.mime_type,
        this.on_boarding_step.payload.file.name,
      );
    }
  }

  setFileData = async (file: File) => {
    this.payload.file.id = this.exists_step
      ? this.on_boarding_step.payload.file.id : this.payload.file.id;
    this.payload.file.name = file.name;
    this.payload.file.mime_type = file.type;
    const base64 = await this.convertFileToBase64(file);
    if (base64) {
      this.payload.file.file_data = base64.toString();
    }
  };

  getIneAsProofOfAddress = async () => {
    this.is_loading = true;
    await this.loadCustomerDocuments();
    if (this.customer_documents.length) {
      const ine = this.customer_documents.find(
        (item) => item.document_type.name === 'ine_front',
      );
      if (ine) {
        const ine_file = await this.get_file_query.execute(ine.customer_document_id);
        this.address_proof = await this.convertBase64ToFile(
          ine_file.content, ine_file.mime_type, ine_file.name,
        );
      }
    }
    this.is_loading = false;
  }

  async saveStep() {
    this.is_loading = true;
    let saved = false;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload = this.payload;
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
        this.exists_step = true;
      }
      saved = true;
    } catch {
      this.messageNotifier.showErrorNotification(
        'Ha ocurrido un error al guardar tu información, inténtalo nuevamente',
      );
    } finally {
      this.is_loading = false;
    }
    return saved;
  }

  initialize = async (screen_file_size: string, service: string) => {
    this.investment_provider_name = service;
    if (service === 'kuspit') {
      this.step_name = 'on_boarding_kuspit_proof_of_address';
    } else {
      this.step_name = 'on_boarding_allianz_proof_of_address';
    }
    this.small_screen = screen_file_size === 'small';
    await this.loadInvestmentsProviders();
    await this.loadSteps();
    this.is_loading = false;
  }
}
